<template>
    <el-tree :data="treeData" :render-content="renderContent" :expand-on-click-node="false" class="json-view"></el-tree>
</template>
<script>
export default {
    name: 'jsonView',
    created() {
        this.parseTreeData();
    },
    props: {
        json: {
            type: [Object, Array],
            required: true
        },
        rootKey: {
            type: String,
            default: 'root'
        }
    },
    data() {
        return {
            treeData: []
        };
    },
    methods: {
        parseTreeData() {
            let id = 0;
            const parse = obj => {
                let root = [];
                if (obj instanceof Object) {
                    for (let key in obj) {
                        if (obj[key] instanceof Array) {
                            root.push({
                                id: ++id,
                                label: key + ': Array[' + obj[key].length + ']',
                                children: parse(obj[key])
                            });
                        } else if (obj[key] instanceof Object) {
                            root.push({
                                id: ++id,
                                label: key + ': Object',
                                children: parse(obj[key])
                            });
                        } else {
                            root.push({
                                id: ++id,
                                label: key + ': ' + obj[key]
                            });
                        }
                    }
                } else if (obj instanceof Array) {
                    for (let i = 0; i < obj.length; i++) {
                        if (obj[i] instanceof Array) {
                            root.push({
                                id: ++id,
                                label: i + ': Array[' + obj[i].length + ']',
                                children: parse(obj[i])
                            });
                        } else if (obj[i] instanceof Object) {
                            root.push({
                                id: ++id,
                                label: i + ': Object',
                                children: parse(obj[i])
                            });
                        } else {
                            root.push({
                                id: ++id,
                                label: i + ': ' + obj[i]
                            });
                        }
                    }
                }
                return root;
            };
            this.treeData = parse(this.json);
        },
        renderContent(h, { node, data, store }) {
            return h(
                'span',
                {
                    class: 'json-view-content'
                },
                [
                    h('span', { class: 'json-view-content-key' }, [data.label.split(':')[0]]),
                    ':',
                    data.label.split(':')[1]
                ]
            );
        }
    },
    watch: {
        json() {
            this.parseTreeData();
        }
    }
};
</script>
<style lang="less">
.json-view .json-view-content {
    cursor: text !important;
    font-family: 'dejavu sans mono', 'droid sans mono', consolas, monaco, 'lucida console', 'courier new', courier,
        monospace, sans-serif;
    font-size: 13px !important;
}

.json-view .json-view-content .json-view-content-key {
    color: @prim;
}
</style>
